import React from "react"
import { getCourseNavigation } from "../../../store/courses"
import LayoutCourse from "../../../layouts/course"

import Seo from "../../../components/seo"
import Heading from "../../../components/heading"
import Stack from "../../../components/stack"
import CourseFooterNext from "../../../components/course-footer-next"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"
import InfoBox from "../../../components/info-box"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "versteckte-ordnung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          title="Bist du bereit?"
          label="Los Geht's"
          to="/kurse/versteckte-ordnung/01-gedicht-erkennen"
        />
      }
      navigation={navigation}
    >
      <Seo title="Die versteckte Ordnung der Gedichte" />
      <Stack>
        <Columns
          alignY="center"
          alignX="space-between"
          collapse={[true, false]}
        >
          <Column>
            <Text sans>Ab 5. und 6. Klasse</Text>
          </Column>
          <Column width="content">
            <TextLink to="/kurse/versteckte-ordnung/fuer-lehrer">
              Informationen für Lehrer*innen
            </TextLink>
          </Column>
        </Columns>
        <Heading as="h2" level={2}>
          Die versteckte Ordnung der Gedichte
        </Heading>
        <InfoBox title="Um was geht es?">
          Gedichte sind häufig nach einem ganz bestimmten Muster, einer
          versteckten Ordnung, aufgebaut. Sie können nach Strophen, nach Reimen
          oder auch nach Lauten geordnet sein. Auch Friedrich Hölderlin hat
          seine Gedichte bis ins Detail ausgetüftelt und nichts dem Zufall
          überlassen. An Beispielen von ihm und anderen Schriftstellerinnen und
          Schriftstellern näherst du dich der Form des Gedichts.
        </InfoBox>
        <InfoBox title="Was ist dein Ziel?">
          In diesem Kurs ist dein Spürsinn gefragt! Schritt für Schritt sammelst
          du Merkmale, die dir helfen, ein Gedicht zu erkennen und seine
          versteckte Ordnung ausfindig zu machen. Am Ende wird dir kein Gedicht
          mehr durch die Lappen gehen.
        </InfoBox>
        <InfoBox title="Was lernst du in diesem Kurs?">
          Im 1. Kapitel lernst du, ein Gedicht von anderen Textformen zu
          unterscheiden und trägst dabei wichtige Bestandteile eines Gedichts
          zusammen. Darauf aufbauend schulst du im 2. Kapitel deinen Blick für
          die Prinzipien und Muster, nach denen ein Gedicht aufgebaut sein kann.
          Du wirst merken, dass Schriftstellerinnen und Schriftsteller ihre
          Worte wie Architekten nach einem genau durchdachten Bauplan anordnen
          und erkennen, wie viele Raffinessen in nur wenigen Worten stecken
          können.
        </InfoBox>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
